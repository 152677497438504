// fa, en
export default {
  fa: {
    me: "من",
    about: "درباره",
    education: "تحصیلات",
    experience: "تجربه‌ها",
    skill: "تخصص‌ها",
    achievement: "دستاوردها",
    blog: "بلاگ",
    courses: "دوره‌ها",
    abolfazl: "ابوالفضل",
    mohajeri: "مهاجری",
    developer: "توسعه دهنده",
    software_engineer: "مهندس نرم‌افزار",
    senior_software_engineer: "مهندس ارشد نرم‌افزار",
    amper: "و",
    researcher: "پژوهشگر",
    about_text: "چند سالی هست که در زمینه علوم کامپیوتر فعالیت می‌کنم و در طول فعالیتم با تکنولوژی‌ها، برنامه‌ها و افراد بسیار خوبی کار کردم.",
    interest: "دوست دارم در این زمینه‌ها فعالیت کنم:",
    download_cv: "دانلود رزومه",
    contact: "تماس",
    contact_me: "تماس با من",
    more: "بیشتر",
    attachment: "ضمیمه",
    download: "دانلود",
    email: "ایمیل",
    gpa: "معدل",
    mobile: "09376215871",

    // Education Section
    grades_modal_text: "بعضی نمرات مهم",
    software_engineering: "مهندسی نرم افزار",
    mathematics_physics: "ریاضی فیزیک",

    ms: "کارشناسی ارشد",
    fum: "دانشگاه فردوسی مشهد",
    fum_year: "1399 - 1401",
    cur_study: "در حال تحصیل",
    advanced_software_engineering: "مهندسی نرم افزار پیشرفته",
    search_engines_and_web_mining: "موتورهای جستجو و وب کاوی",
    data_mining: "داده کاوی",
    semantic_web: "وب معنایی",
    knowledge_base_management: "مدیریت پایگاه دانش",

    bachelor: "مقطع لیسانس",
    iaum: "دانشگاه آزاد اسلامی واحد مشهد",
    iaum_year: "1395 - 1399",
    advanced_programming: "برنامه سازی پيشرفته",
    data_structures: "ساختمان هاي داده",
    algorithms: "طراحی الگوريتم ها",
    signals_and_systems: "سيگنال ها و سيستم ها",
    artificial_intelligence_and_expert_systems: "هوش مصنوعی و سيستم هاي خبره",
    database: "پایگاه داده",
    robotics_basics: "اصول رباتیکز",

    diploma: "دیپلم",
    mobin: "دبیرستان نمونه دولتی مبین",
    mobinYear: "1391 - 1395",

    // Experience Section
    full_time: "تمام وقت",
    part_time: "پاره وقت",
    web_developer: "توسعه دهنده وب",

    freelancing: "فریلنسینگ",
    freelancing_place: "همه جا",
    freelancing_date: "1393 - حال",

    backend: "توسعه‌دهنده بک‌اند",
    neshan: "<small> نقشه و مسیریاب </small> نشان",
    neshan_date: "1401 - حال",

    member: "عضو تیم",
    quera: "کوئرا",
    quera_date: "1399 - 1401",

    mobintabaran: "سازه های هوشمند مبین طابران",
    mobintabaran_date: "1398 - 1399",

    // Skills Section
    try: "من با این تکنولوژی‌ها هم کار کردم",

    // Achievement Section
    scoreboard: "جدول امتیازات",
    certificate: "گواهی",
    participant: "شرکت کننده",
    team: "تیم",

    techolympics_rank: "مقام سیزدهم",
    techolympics_time: "مهر 1403",

    connect6_rank: "مقام اول",
    connect6_time: "اردیبهشت 97",

    acm_rank: "مقام بیست و چهارم",
    acm_time: "آذر 96",

    acm_judge: "داور مسابقات برنامه نویسی در CCC8",
    judge_time: "آذر 97",

    fanavard_rank: "گواهی",
    fanavard_time: "آذر 96",

    aichallenge_rank: "گواهی رقابت‌های هوش مصنوعی",
    aichallenge_time: "فروردین 1400",

    iccke: "گواهی برگزاری کارگاه در کنفرانس ICCKE 2022",
    iccke_time: "آبان 1401",
    iccke_sub: "کارگاه 4 ساعته با موضوع هوش مصنوعی قابل توضیح",
    iccke_site: "سایت کنفرانس",

    explainable_holodetect: "روش تشخیص خطای قابل توضیح <br> برای داده های ساختاریافته با استفاده از چارچوب HoloDetect",
    explainable_holodetect_time: "آذر 1402",
    explainable_holodetect_sub: "سیزدهمین کنفرانس بین المللی مهندسی کامپیوتر و دانش (ICCKE)",
    explainable_holodetect_site: "لینک مقاله در IEEE",

    codecup4_judge: "مقام چهاردهم",
    codecup4_time: "خرداد 97",

    // Contact Section
    contactText: "برای اطلاعات بیشتر با من در تماس باشید.",
    contactPlace: "ایران، مشهد",
    contactLink: "هم‌چنین از طریق این <a href='https://blog.abolfazlmohajeri.ir/contact-me' target='_blank'> لینک </a>، می‌توانید برای ارتباط با من استفاده کنید.",

    // Blog Section
    visit_blog: "همه پست‌ها",

    // Courses Section
    register: "ثبت‌نام",
    laravel_course: "جامپ بک‌اند با لاراول",
    laravel_teachers: "ابوالفضل مهاجری، نیما حیدری‌نسب و ...",

    linux_course: "آموزش کاربردی لینوکس (از 0 تا Z)",
    linux_teachers: "ابوالفضل مهاجری، سید علی بابایی و ...",

    db_course: "آموزش عملی دیتابیس",
    db_teachers: "ابوالفضل مهاجری، نیما حیدری‌نسب و ...",

    // Footer Section
    made: "ساخته شده با ",
    by: "توسط ",
  },
  en: {
    me: "Me",
    about: "About",
    education: "Education",
    experience: "Experiences",
    skill: "Skills",
    achievement: "Achievements",
    blog: "Blog",
    courses: "Courses",
    abolfazl: "Abolfazl",
    mohajeri: "Mohajeri",
    developer: "Developer",
    software_engineer: "Software Engineer",
    senior_software_engineer: "Senior Software Engineer",
    amper: "&",
    researcher: "Researcher",
    about_text: "I’ve been working in computer science field for several years and during my job experience, I acquired practical skills with interesting technologies, tasks and people.",
    interest: "I like to work in these fields:",
    download_cv: "Download Resume",
    contact: "Contact",
    contact_me: "Contact Me",
    more: "More",
    attachment: "Attachment",
    download: "Download",
    email: "Email",
    gpa: "GPA",
    mobile: "+989376215871",

    // Education Section
    grades_modal_text: "Some Important Grades",
    software_engineering: "Software Engineering",
    mathematics_physics: "Mathematics And Physics",

    ms: "Master of Science",
    fum: "Ferdowsi University Of Mashhad",
    fum_year: "2020 - 2022",
    cur_study: "Currently Studying",
    advanced_software_engineering: "Advanced Software Engineering",
    search_engines_and_web_mining: "Search Engines and Web Mining",
    data_mining: "Data Mining",
    semantic_web: "Semantic Web",
    knowledge_base_management: "Knowledge Base Management",

    bachelor: "Bachelor’s Degree",
    iaum: "Islamic Azad University Of Mashhad",
    iaum_year: "2016 - 2020",
    advanced_programming: "Advanced Programming",
    data_structures: "Data Structures",
    algorithms: "Algorithms Design",
    signals_and_systems: "Signals And Systems",
    artificial_intelligence_and_expert_systems: "Artificial Intelligence and Expert Systems",
    database: "Database",
    robotics_basics: "Robotics Basics",

    diploma: "Diploma",
    mobin: "Mobin High School (Magnet School)",
    mobinYear: "2012 – 2016",

    // Experience Section
    full_time: "Full Time",
    part_time: "Part Time",
    web_developer: "Web Developer",

    freelancing: "Freelancing",
    freelancing_place: "All Over The World",
    freelancing_date: "2014 - Present",

    backend: "Backend Developer",
    neshan: "Neshan <small> Maps & Navigation </small>",
    neshan_date: "2022 - Present",

    member: "Member Of",
    quera: "Quera",
    quera_date: "2020 - 2022",

    mobintabaran: "Mobin Tabaran Intelligence Structures",
    mobintabaran_date: "2018 - 2019",

    // Skills Section
    try: "I also tried this technologies",

    // Achievement Section
    scoreboard: "ScoreBoard",
    certificate: "Certificate",
    participant: "Participant",
    team: "Team",

    techolympics_rank: "Thirteen Place",
    techolympics_time: "October 2024",

    connect6_rank: "First Place",
    connect6_time: "May 2018",

    acm_rank: "Twenty Fourth Place",
    acm_time: "December 2017",

    acm_judge: "Judge Of Contest in CCC8",
    judge_time: "December 2018",

    fanavard_rank: "Certification",
    fanavard_time: "December 2016",

    aichallenge_rank: "AI Challenge Certification",
    aichallenge_time: "April 2021",

    iccke: "Certificate OF ICCKE Workshop Presentation",
    iccke_time: "November 2022",
    iccke_sub: "4 Hour Workshop Entitled Explainable Artificial Intelligence",
    iccke_site: "Conference Site",

    explainable_holodetect: "Explainable Error Detection Method <br> For Structured Data Using HoloDetect Framework",
    explainable_holodetect_time: "November 2023",
    explainable_holodetect_sub: "13th International Conference on Computer and Knowledge Engineering (ICCKE)",
    explainable_holodetect_site: "Paper Link in IEEE",

    codecup4_judge: "Fourteenth Place",
    codecup4_time: "June 2018",

    // Contact Section
    contactText: "Do Not Hesitate To Contact Me For More Information.",
    contactPlace: "Mashhad, Iran",
    contactLink: "You can also use this <a href='https://blog.abolfazlmohajeri.ir/contact-me' target='_blank'> link </a> to contact me.",

    // Blog Section
    visit_blog: "All Posts",

    // Courses Section
    register: "Register",

    laravel_course: "Backend Jump With Laravel",
    laravel_teachers: "Abolfazl Mohajeri, Nima HeydariNasab and ...",

    linux_course: "Practical Linux Course",
    linux_teachers: "Abolfazl Mohajeri, Seyed Ali Babaei and ...",

    db_course: "Practical Database Course",
    db_teachers: "Abolfazl Mohajeri, Nima HeydariNasab and ...",

    // Footer Section
    made: "Made with ",
    by: "by ",
  }
}
