<template>
    <div class="row m-0">
        <div class="col-12">
            <div class="row headers text-dark text-center py-3">
                <h4 class="m-0 mx-auto"> {{ $t("education") }} </h4>
            </div>
            <div class="row">
                <div class="sections p-3">
                    <div class="card w-100 border-0">
                        <div class="card-title mb-0">
                            <div class="float-left">
                                <div class="media">
                                    <img src="@/assets/images/fum.png" class="uni-logos" alt="fum">
                                    <div class="media-body ml-2">
                                        <span class="d-block"> {{ $t("ms") }} </span>
                                        <span class="d-block"> {{ $t("fum") }} </span>
                                        <span class="d-block"> {{ $t("software_engineering") }} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="float-right">
                                <div class="ltr">
                                    <small> {{ $t("fum_year") }} </small>
                                </div>
                                <vs-button class="m-0 mt-1 ml-auto" size="mini" transparent active color="dark" @click="active2 = !active2">
                                    <i class='bx bx-link mr-1'></i> {{ $t("attachment") }}
                                </vs-button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="w-100">
                            <div class="float-left pt-2">
                                <small>
                                    <a href="https://mohajeri.student.um.ac.ir" target="_blank"> Website </a>
                                    - <a href="https://wtlab.ir/user/mohajeri" target="_blank"> WTLAB </a>
                                    - mohajeri@mail.um.ac.ir
                                </small> <br>
                                <small class="text-muted"> {{ $t("gpa") }} 17.49 / 20 </small>
                            </div>
                            <div class="float-right">
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="card w-100 border-0 mt-4">
                        <div class="card-title mb-0">
                            <div class="float-left">
                                <div class="media mt-1">
                                    <img src="@/assets/images/iaum.png" class="uni-logos" alt="iaum">
                                    <div class="media-body ml-2">
                                        <span class="d-block"> {{ $t("bachelor") }} </span>
                                        <span class="d-block"> {{ $t("iaum") }} </span>
                                        <span class="d-block"> {{ $t("software_engineering") }} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="float-right">
                                <div class="ltr">
                                    <small> {{ $t("iaum_year") }} </small>
                                </div>
                                <vs-button class="m-0 mt-1 ml-auto" size="mini" transparent active color="dark" @click="active1 = !active1">
                                    <i class='bx bx-link mr-1'></i> {{ $t("attachment") }}
                                </vs-button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="w-100">
                            <div class="float-left">
                                <small class="text-muted"> {{ $t("gpa") }} 16.26 / 20 </small>
                            </div>
                            <div class="float-right">

                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="text-center">
                        <vs-button class="d-inline mt-3" transparent active color="dark" data-toggle="collapse" data-target="#collapseEducation" aria-expanded="false" aria-controls="collapseEducation">
                            {{ $t("more") }}
                        </vs-button>
                    </div>
                    <div class="collapse" id="collapseEducation">
                        <div class="card w-100 border-0 mt-3">
                            <div class="card-title mb-0">
                                <div class="float-left">
                                    <span class="d-block"> {{ $t("diploma") }} </span>
                                    <span class="d-block"> {{ $t("mobin") }} </span>
                                    <span class="d-block"> {{ $t("mathematics_physics") }} </span>
                                </div>
                                <div class="float-right">
                                    <div class="ltr">
                                        <small> {{ $t("mobinYear") }} </small>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="w-100">
                                <div class="float-left">
                                    <small class="text-muted"> {{ $t("gpa") }} 17.65 / 20 </small>
                                </div>
                                <div class="float-right">
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <vs-dialog v-model="active1" :class="{'rtlModal' : this.language === 'fa' }">
            <template #header class="pb-0">
                <h4 class="not-margin">
                    {{ $t("grades_modal_text") }}
                </h4>
            </template>
            <ul class="list-group list-group-flush text-center p-0">
                <li class="list-group-item"> {{ $t("advanced_programming") }} 20 / 20 </li>
                <li class="list-group-item"> {{ $t("data_structures") }} 18.60 / 20 </li>
                <li class="list-group-item"> {{ $t("algorithms") }} 19 / 20 </li>
                <li class="list-group-item"> {{ $t("signals_and_systems") }} 17.25 / 20 </li>
                <li class="list-group-item"> {{ $t("artificial_intelligence_and_expert_systems") }} 17.5 / 20 </li>
                <li class="list-group-item"> {{ $t("database") }} 16.25 / 20 </li>
                <li class="list-group-item"> {{ $t("robotics_basics") }} 18 / 20 </li>
            </ul>
        </vs-dialog>

        <vs-dialog v-model="active2" :class="{'rtlModal' : this.language === 'fa' }">
            <template #header class="pb-0">
                <h4 class="not-margin">
                    {{ $t("grades_modal_text") }}
                </h4>
            </template>
            <ul class="list-group list-group-flush text-center p-0">
                <li class="list-group-item"> {{ $t("advanced_software_engineering") }} 17 / 20 </li>
                <li class="list-group-item"> {{ $t("search_engines_and_web_mining") }} 17.30 / 20 </li>
                <li class="list-group-item"> {{ $t("data_mining") }} 18.95 / 20 </li>
                <li class="list-group-item"> {{ $t("semantic_web") }} 18.56 / 20 </li>
                <li class="list-group-item"> {{ $t("knowledge_base_management") }} 17.70 / 20 </li>
            </ul>
        </vs-dialog>

    </div>
</template>

<script>
    export default {
        name: "Education",
        data() {
            return {
                language: this.$i18n.locale,
                active1: false, // IAUM
                active2: false // FUM
            }
        },
        mounted() {

        },
        methods : {

        }
    }
</script>

<style>

</style>

<style scoped>

</style>
