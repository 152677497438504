<template>
  <div id="app">
    <!-- route outlet -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import "assets/css/app.scss";
  @import "assets/css/styles.scss";
  .rtl {
    font-family: ShabnamMedium !important;
    direction: rtl !important;
    text-align: right !important;
    @import "assets/css/rtl.scss";
  }
  .rtlModal {
    font-family: ShabnamMedium !important;
  }
  @import "assets/fonts/boxicons.min.css";
</style>
