<template>
    <!-- route outlet -->
    <router-view></router-view>
</template>

<script>

    export default {
        name: "Master",
        components: {

        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods : {

        }
    }
</script>

<style>

</style>

<style scoped>

</style>
