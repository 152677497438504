import Vue from 'vue'

Vue.config.productionTip = false;

window.$ = window.JQuery = require('jquery');
require("bootstrap");

import App from './App.vue'

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax);

import 'devicon/devicon.min.css'

import router from "./router"

import i18n from "./i18n/i18n"

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');