<template>
    <div class="row m-0">
        <div class="col-12">
            <div class="row headers text-dark text-center py-3">
                <h4 class="m-0 mx-auto"> {{ $t("about") }} </h4>
            </div>
            <div class="row">
                <div class="sections p-3">
                    <p class="text-justify m-0 mb-2">  {{ $t("about_text") }} </p>
                    <p class="text-justify m-0 mb-2">  {{ $t("interest") }} </p>
                    <p class="text-justify en-inputs m-0 mb-2">
                        <span class="badge badge-dark p-2 m-1"> Backend Development </span>
                        <span class="badge badge-dark p-2 m-1"> Data Science </span>
                    </p>
                    <div class="text-center">
                        <vs-button class="d-inline" href="/AbolfazlMohajeriResume.pdf" blank transparent active
                                   color="dark">
                            {{ $t("download_cv") }}
                        </vs-button>
                        <vs-button class="d-inline" transparent active color="dark" id="contact-btn">
                            {{ $t("contact_me") }}
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery"

    export default {
        name: "About",
        data() {
            return {

            }
        },
        mounted() {
            $("#contact-btn").on("click", function (e) {
                e.preventDefault();
                let myElement = document.getElementById("contact");
                let topPos = myElement.offsetTop;
                $("html").animate({
                    scrollTop: topPos - 53
                }, 'slow');
            });
        },
        methods : {

        }
    }
</script>

<style>

</style>

<style scoped>

</style>
