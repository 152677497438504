<template>
    <div class="row m-0">
        <div class="col-12 justify-content-center">
            <div class="row headers text-dark text-center all-box-shadows py-3">
                <h6 class="m-0 mx-auto"> {{ $t("made") }} <img src="@/assets/images/heart.png" class="footer-heart" alt="heart"> {{ $t("by") }} <a href="Abolfazl Mohajeri"> {{ $t("abolfazl") }} {{ $t("mohajeri") }} </a> </h6>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {

            }
        },
        mounted() {

        },
        methods : {

        }
    }
</script>

<style>

</style>

<style scoped>

</style>
