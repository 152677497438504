<template>
    <div class="container" :class="{'rtl' : this.language === 'fa' }">
        <div class="row justify-content-center">
            <div class="col-12 col-md-9 col-lg-8 sections all-box-shadows p-0" id="me">
                <nav id="main-nav-bar" class="navbar navbar-expand-lg navbar-light sticky-top">
                    <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#nav-bar-main" aria-controls="nav-bar-main" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="navbar-brand choose-language-icons">
                        <vs-button class="d-inline" icon color="dark" border :active="language === 'fa'" @click="changeLocale('fa')">
                            FA
                        </vs-button>
                        <vs-button class="d-inline" icon color="dark" border :active="language === 'en'" @click="changeLocale('en')">
                            EN
                        </vs-button>
                    </div>
                    <div class="collapse navbar-collapse" id="nav-bar-main">
                        <ul class="navbar-nav mt-2 mt-lg-0 text-center p-0">
                            <li class="nav-item">
                                <a class="nav-link" href="#me"> {{ $t("me") }} </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#education"> {{ $t("education") }} </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#experience"> {{ $t("experience") }} </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#skills"> {{ $t("skill") }} </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#achievement"> {{ $t("achievement") }} </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#contact"> {{ $t("contact") }} </a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div class="row justify-content-center header pt-3 pb-2 m-0">
                    <div class="col-11 col-sm-8 col-md-7 col-lg-6">
                        <div class="about-section">
                            <vs-avatar class="logo all-box-shadows" size="90" badge badge-color="success">
                                <img src="@/assets/images/me.jpg" alt="me">
                                <template #badge>
                                    <br>
                                </template>
                            </vs-avatar>
                            <div class="name-mark all-box-shadows">
                                <div class="d-block"> {{ $t("abolfazl") }} </div>
                                <div class="d-block"> {{ $t("mohajeri") }} </div>
                            </div>
                        </div>
                        <h4 class="my-3 text-center"> {{ $t("software_engineer") }} </h4>
                        <div class="my-2 text-center">
                            <vs-button class="d-inline" href="https://stackoverflow.com/users/8495783/abolfazl-mohajeri" blank icon color="dark">
                                <i class='bx bxl-stack-overflow'></i>
                            </vs-button>
                            <vs-button class="d-inline" href="https://github.com/abmohajeri" blank icon color="dark">
                                <i class='bx bxl-github'></i>
                            </vs-button>
                            <vs-button class="d-inline" href="https://t.me/abmohajeri" blank icon color="dark">
                                <i class='bx bxl-telegram'></i>
                            </vs-button>
                            <vs-button class="d-inline" href="https://www.instagram.com/abmohajeri" blank icon color="dark">
                                <i class='bx bxl-instagram'></i>
                            </vs-button>
                            <vs-button class="d-inline" href="https://www.linkedin.com/in/abmohajeri" blank icon color="dark">
                                <i class='bx bxl-linkedin'></i>
                            </vs-button>
                        </div>
                    </div>
                </div>
                <About></About>
                <Education id="education"></Education>
                <Experience id="experience"></Experience>
                <Skill id="skills"></Skill>
                <Achievement id="achievement"></Achievement>
                <Contact id="contact"></Contact>
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import About from "../../components/About";
import Education from "../../components/Education";
import Experience from "../../components/Experience";
import Skill from "../../components/Skill.vue";
import Achievement from "../../components/Achievement";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import $ from 'jquery';

export default {
        name: "Index",
        components: {
            About,
            Education,
            Experience,
            Skill,
            Achievement,
            Contact,
            Footer
        },
        data() {
            return {
                language: this.$i18n.locale
            }
        },
        beforeMount() {

        },
        mounted() {
            $("#nav-bar-main a.nav-link").on("click", function (e) {
                e.preventDefault();
                let url = e.target.href.split("/");
                let myElement = document.getElementById(url[url.length - 1].slice(3));
                let topPos = myElement.offsetTop;
                $("html").animate({
                    scrollTop: topPos - 53
                }, 'slow');
            });
        },
        methods : {
            changeLocale: function(lang) {
                if(this.language !== lang) {
                    this.language = lang;
                    this.$router.push(lang);
                }
            }
        }
    }
</script>

<style>

</style>

<style scoped>

</style>
